@import '~antd/dist/antd';
@import 'fonts.less';

html,
body,
#root {
  height: 100%;
}

.ant-layout {
  min-height: 100vh;
}

canvas {
  display: block;
}

body {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

ul,
ol {
  list-style: none;
}

@media (max-width: @screen-xs) {
  .ant-table {
    width: 100%;
    overflow-x: auto;
    &-thead > tr,
    &-tbody > tr {
      > th,
      > td {
        white-space: pre;
        > span {
          display: block;
        }
      }
    }
  }
}

// IE11
@media screen and(-ms-high-contrast: active), (-ms-high-contrast: none) {
  body .ant-design-pro > .ant-layout {
    min-height: 100vh;
  }
}

// Integrity dark menu styling
.ant-menu-dark {
  &.ant-menu-inline .ant-menu-item:after {
    border-right: 3px solid @primary-color !important;
    content: "";
  }

  &.ant-menu-inline-collapsed {
    .ant-menu-submenu-selected {
      background-color: @menu-dark-item-active-bg;
    }

    .ant-menu-item-selected, .ant-menu-submenu-selected {
      border-right: 3px solid @primary-color !important;
    }
  }
}

@primary-color: #D21919;@link-color: #D21919;@layout-body-background: #E3E7ED;@layout-sider-background: #23282D;@menu-dark-bg: #23282D;@menu-dark-submenu-bg: rgba(0, 0, 0, 0.45);@menu-dark-item-active-bg: fade(@primary-color, 15%);