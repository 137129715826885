@import '~antd/es/style/index.less';

.ant-drawer-close {
  color: rgba(255, 255, 255, 0.45) !important;
}

.ant-pro-sider-collapsed-button {
  border-top: 0 !important;
}

.ant-pro-sider-logo img {
  height: 18px !important;
}

.ant-pro-sider.ant-layout-sider-collapsed .ant-pro-sider-logo {
  padding: 16px 15px !important;
}

.ant-pro-sider-logo h1 {
  font-family: EB Garamond, serif !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 24px !important;
  letter-spacing: 0.15em;
}

.ant-pro-sider-fixed {
  position: fixed;
}

@primary-color: #D21919;@link-color: #D21919;@layout-body-background: #E3E7ED;@layout-sider-background: #23282D;@menu-dark-bg: #23282D;@menu-dark-submenu-bg: rgba(0, 0, 0, 0.45);@menu-dark-item-active-bg: fade(@primary-color, 15%);